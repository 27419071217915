import Vue from 'vue'
//import App from './dashboard/App.vue'
import App from './App.vue'
import router from './router'
import vSelect from 'vue-select'
import ToastPlugin from 'vue-toast-notification';

Vue.use(ToastPlugin);
import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)


import store from './client/store'
import 'vue-toast-notification/dist/theme-sugar.css';
import VModal from 'vue-js-modal'

Vue.use(VModal);
Vue.component("v-select", vSelect);
import VueStepWizard from 'vue-step-wizard'
Vue.use(VueStepWizard);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

const VueInputMask = require('vue-inputmask').default

Vue.use(VueInputMask)

import 'vue-step-wizard/dist/vue-step-wizard.css'
import 'vue-select/dist/vue-select.css';
import "./plugins/vuetify-money.js";


window.$ = window.jQuery = require('jquery');


// Firebase
import '@/dashboard/firebase/firebaseConfig'



// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer);

import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAWpDQuV5BGkjUtUEIOeKLmcrg4QtBRS2E',
    libraries: 'places',
    // Add your here your google map api key
  }
});


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./dashboard/assets/css/iconfont.css')

Vue.config.productionTip = false

new Vue({
  router,
  store,

  render: h => h(App)
}).$mount('#app')
