import httpService from "../../http/requests/app"


export default {
    state: {

    },
    getters: {

    },
    mutations: {

    },

    actions: {

        forgotPassword({commit}, data){
            return new Promise((resolve,reject) => {

                httpService.forgotPassword(data)
                    .then(response => {


                        // If there's user data in response
                        if(response.status == 200){

                            resolve()


                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        generateToken({commit}, data){
            return new Promise((resolve,reject) => {

                httpService.generateToken(data)
                    .then(response => {
                        resolve(response.data);

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        changePassword({commit}, data){
            return new Promise((resolve,reject) => {

                httpService.changePassword(data)
                    .then(response => {


                        // If there's user data in response
                        if(response.status == 200){

                            resolve()


                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        resetPassword({commit}, data){
            return new Promise((resolve,reject) => {

                httpService.resetPassword(data)
                    .then(response => {


                        // If there's user data in response
                        if(response.status == 200){

                            resolve()


                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        createUser({commit}, data){
            return new Promise((resolve,reject) => {

                httpService.createUser(data)
                    .then(response => {


                        // If there's user data in response
                        if(response.status == 200){

                            resolve()


                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        }

    }






};
