
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


  /*let firebaseConfig = {
        apiKey: "AIzaSyAnTzL3aMDEXZzA56tpuZz0ZqklVgCWizs",
        authDomain: "tia-switch-uat.firebaseapp.com",
        projectId: "tia-switch-uat",
        storageBucket: "tia-switch-uat.appspot.com",
        messagingSenderId: "828378304907",
        appId: "1:828378304907:web:3f933f5c0640a2a68a1f8e",
        measurementId: "G-9Y98566ZDD"
    }; */

let firebaseConfig = {
  apiKey: "AIzaSyA95c8wces2zeunBbGSZ1k9hEZztyZ7wyk",
  authDomain: "tiaswitch-prod.firebaseapp.com",
  projectId: "tiaswitch-prod",
  storageBucket: "tiaswitch-prod.appspot.com",
  messagingSenderId: "622650518753",
  appId: "1:622650518753:web:89da4fac97610d02d31ba0",
  measurementId: "G-36PYN08S6F"
}

// Your web app's Firebase configuration


// Initialize Firebase
const app = initializeApp(firebaseConfig);
