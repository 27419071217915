import httpService from "../../http/requests/app"


export default {
    state: {

    },
    getters: {

    },
    mutations: {

    },

    actions: {

        getMyPolicies({commit}, data){

            return new Promise((resolve,reject) => {
                httpService.getPolicies(data.email)
                    .then(response => {

                        console.log(response.data);

                        if(response.data.success){
                            resolve(response.data.policyModels);
                        }
                        else{
                            resolve([]);
                        }

                    })
                    .catch(error => {
                        reject([])

                        //reject({message: axios.defaults.headers})
                    })
            })
        },

        getMyPolicy({commit}, data){

            return new Promise((resolve,reject) => {
                httpService.getPolicy(data.id)
                    .then(response => {

                        resolve(response.data);

                    })
                    .catch(error => {
                        reject([])

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        getKilometerWallet({commit}){
            return new Promise((resolve,reject) => {
                httpService.getKilometerWallet()
                    .then(response => {

                        resolve(response.data);

                    })
                    .catch(error => {
                        reject([])

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        getPolicyBenefits({commit}){
            return new Promise((resolve,reject) => {
                httpService.getPolicyBenefits()
                    .then(response => {

                        resolve(response.data);

                    })
                    .catch(error => {
                        reject([])

                        //reject({message: axios.defaults.headers})
                    })
            })
        },


        makeMobileMoneyPayment({commit}, data){
            return new Promise((resolve,reject) => {

                httpService.makePayment(data)
                    .then(response => {



                        // If there's user data in response
                        if(response.status == 200){

                            resolve(response.data);

                            //this.dispatch('campaigns/getCampaigns')

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        getTransactionsByMerchantId({commit}, data){
            //getTransactionsByMerchantId

            return new Promise((resolve,reject) => {

                httpService.getTransactionsByMerchantId(data.transactionId)
                    .then(response => {


                        // If there's user data in response
                        if(response.data.response.data.status == "SUCCESS"){

                            resolve()

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        makeCardPayment({commit}, data){
            return new Promise((resolve,reject) => {

                httpService.makeCardPayment(data)
                    .then(response => {


                        console.log(response.data);


                        // If there's user data in response
                        if(response.status == 200){

                            resolve(response.data);

                            //this.dispatch('campaigns/getCampaigns')

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },

        getQuote({commit}, data){
            return new Promise((resolve,reject) => {

                httpService.getQuote(data)
                    .then(response => {

                        console.log(response.data);

                        // If there's user data in response
                        if(response.data.success){



                            resolve(response.data)



                            //this.dispatch('campaigns/getCampaigns')

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },

        updatePersonalQuoteInformation({commit}, data){
            return new Promise((resolve,reject) => {

                httpService.updatePersonalQuoteInformation(data.quoteId, data.quoteData)
                    .then(response => {


                        // If there's user data in response
                        if(response.data.success){


                            resolve(response.data.data)


                            //this.dispatch('campaigns/getCampaigns')

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        uploadDocuments({commit}, data){
            return new Promise((resolve,reject) => {

                httpService.uploadDocuments(data.documents, data.quoteId)
                    .then(response => {




                        // If there's user data in response
                        if(response.data.message == "Upload successfull"){
                            console.log(response.data.message);
                            resolve()

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },

        updateValuation({commit}, data){
            return new Promise((resolve,reject) => {

                httpService.updateValuation(data.quoteId, data.quoteData)
                    .then(response => {

                        // If there's user data in response
                        if(response.data.success){


                            resolve(response.data.data)


                            //this.dispatch('campaigns/getCampaigns')

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },

        finalizeQuoteDetails({commit},data){
            return new Promise((resolve,reject) => {

                httpService.finalizeQuoteDetails(data.quoteId)
                    .then(response => {

                        // If there's user data in response
                        if(response.data.success){

                            console.log(response.data);


                            resolve(response.data.data)


                            //this.dispatch('campaigns/getCampaigns')

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },

        getVehicleMakes({commit}){

            return new Promise((resolve,reject) => {
                httpService.getVehicleMakes()
                    .then(response => {

                        resolve(response.data);

                    })
                    .catch(error => {
                        reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        getQuoteDetails({commit}, data){
            return new Promise((resolve,reject) => {
                httpService.getQuoteDetails(data.id)
                    .then(response => {

                        resolve(response.data.data);

                    })
                    .catch(error => {
                        reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        getVehicleModels({commit}, data){

            return new Promise((resolve,reject) => {
                httpService.getVehicleModels(data.id)
                    .then(response => {

                        resolve(response.data);

                    })
                    .catch(error => {
                        reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        }



    }






};
