
import Vue from "vue";
import Vuex from "vuex";

import quotationData from "./modules/quotation";
import userAccountData from "./modules/userAccount";

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    quotationData,
    userAccountData

  }
});
